<template>
	<div>
		<!--	选择月份-->
		<div style="margin-bottom: 2%;margin-left: 1%">
			<el-date-picker
				v-model="month"
				type="month"
				format="YYYY-MM"
				value-format="YYYY-MM"
				placeholder="请选择月份"
			/>
		</div>
	<!--	表格信息-->
		<div>
			<vxe-table
				border
				resizable
				:align="allAlign"
				:data="tableData"
				size="small"
				max-height="800"
				highlight-hover-row
				:span-method="mergeRowMethod"
				:cell-style="cellStyle"
				ref="xTable1"
				:export-config="{}"
			>
				<vxe-column type="seq" title="序号" width="70"></vxe-column>
				<vxe-column field="bdst" title="工区" width="180"></vxe-column>
				<vxe-column field="type" title="钢材类型" width="180"></vxe-column>
				<vxe-column field="unit" title="单位" width="70"></vxe-column>
				<vxe-column field="yxCh" title="允许超耗系数" width="100"></vxe-column>
				<vxe-column field="jc" title="进场量(物资)" width="180"></vxe-column>
				<vxe-column field="kc" title="库存量(物资)" width="100"></vxe-column>
				<vxe-column field="ft" title="废铁量(物资)" width="100"></vxe-column>
				<vxe-column field="sj" title="设计量(含变更)" width="100"></vxe-column>
				<vxe-column field="ztAdd" title="主体交底增加量" width="100"></vxe-column>
				<vxe-column field="fsAdd" title="附属交底增加量" width="100"></vxe-column>
				<vxe-column field="yh" title="应耗量" width="100"></vxe-column>
				<vxe-column field="sh" title="实耗量" width="100"></vxe-column>
				<vxe-column field="yhJc" title="应耗实耗节+超-" width="100"></vxe-column>
				<vxe-column field="allJc" title="总体节+超-" width="100"></vxe-column>
			</vxe-table>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowMonth2} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			month: '',
			allAlign: 'center',
			tableData: []
		}
	},
	created() {
		this.month = setNowMonth2()
		this.getAllMessage()
	},
	methods: {
		getAllMessage(){
			this.axios.get('/materialWaterproofConsumeTest/getSteelAllMessage', (response) => {
				this.tableData = response.obj
			},{
				month: this.month
			})
		},
		mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
			const fields = ['bdst']
			const cellValue = row[column.property]
			if (cellValue && fields.includes(column.property)) {
				const prevRow = visibleData[_rowIndex - 1]
				let nextRow = visibleData[_rowIndex + 1]
				if (prevRow && prevRow[column.property] === cellValue) {
					return {rowspan: 0, colspan: 0}
				} else {
					let countRowspan = 1
					while (nextRow && nextRow[column.property] === cellValue) {
						nextRow = visibleData[++countRowspan + _rowIndex]
					}
					if (countRowspan > 1) {
						return {rowspan: countRowspan, colspan: 1}
					}
				}
			}
		},
		cellStyle ({ row, rowIndex, column }) {
			if (column.property === 'addup') {
				if (row.addup === 0) {
					return {
						backgroundColor: 'red',
						color: '#ffffff'
					}
				}
			}
			if (column.property === 'start') {
				if (row.start === '/') {
					return {
						backgroundColor: 'green',
						color: '#ffffff'
					}
				}
			}
		}
	}
}
</script>

<style>

</style>