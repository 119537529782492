<template>
	<div>
		<div>
			<Header></Header>
		</div>
		<div id="admindiv1">
			<div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item style="font-size: 13px">材料节超</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>

		<div id="forms_div2">
			<el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
				<el-tab-pane label="防水材料节超" name="a"><Waterproof_material v-if="a" /></el-tab-pane>
				<el-tab-pane label="钢材节超" name="b"><Steels_material v-if="b" /></el-tab-pane>
			</el-tabs>

		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import Header from "@/views/header.vue";
import Waterproof_material from "@/views/material/material_nodal/waterproof_material.vue";
import Steels_material from "@/views/material/material_nodal/steels_material.vue";

export default {
	name: "",
	components: {Waterproof_material, Header, Steels_material},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			activeName: 'a',
			a: true,
			b: false
		}
	},
	created() {
		if (!this.$route.params.key){
			this.$router.push("/manage")
		}
	},
	methods: {
		handleClick(tab, event) {
			if (tab.props.name === 'a') {
				this.a = true
				this.b = false
			}
			if (tab.props.name === 'b') {
				this.b = true
				this.a = false
			}
		}
	}
}
</script>

<style>
#admindiv1{
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	justify-content: space-between;
}
#forms_div2{
	border-radius: 5px;
	margin: 0 auto;
	background-color: white;
	width: 95%;
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 500px;
}
</style>