<template>
	<div>
		<!--	选择月份-->
		<div style="margin-bottom: 2%;margin-left: 1%">
			<el-date-picker
				v-model="month"
				type="month"
				format="YYYY-MM"
				value-format="YYYY-MM"
				placeholder="请选择月份"
				@change="getAllMessage"
			/>
		</div>
		<div>
			<vxe-table
				border
				resizable
				:align="allAlign"
				:data="tableData"
				size="small"
				max-height="800"
				highlight-hover-row
				:span-method="mergeRowMethod"
				:cell-style="cellStyle"
				ref="xTable1"
				:export-config="{}"
			>
				<vxe-column type="seq" title="序号" width="70"></vxe-column>
				<vxe-column field="bdst" title="工区" width="180"></vxe-column>
				<vxe-column field="bdstXx" title="工点" width="180"></vxe-column>
				<vxe-column field="type" title="类型" width="180"></vxe-column>
				<vxe-column field="position" title="部位" width="180"></vxe-column>
				<vxe-column field="material" title="材料" width="250"></vxe-column>
				<vxe-column field="unit" title="单位" width="70"></vxe-column>
				<vxe-column field="coefficient" title="允许超耗系数" width="70"></vxe-column>
				<vxe-colgroup title="本期">
					<vxe-column field="nowSj" title="设计量(含变更)" width="100"></vxe-column>
					<vxe-column field="nowYh" title="应耗量" width="100"></vxe-column>
					<vxe-column field="nowSh" title="实耗量" width="100"></vxe-column>
					<vxe-column field="nowJc" title="节+超-" width="100"></vxe-column>
					<vxe-column field="nowRate" title="超耗率" width="100"></vxe-column>
				</vxe-colgroup>
				<vxe-colgroup title="本年">
					<vxe-column field="yearSj" title="设计量(含变更)" width="100"></vxe-column>
					<vxe-column field="yearYh" title="应耗量" width="100"></vxe-column>
					<vxe-column field="yearSh" title="实耗量" width="100"></vxe-column>
					<vxe-column field="yearJc" title="节+超-" width="100"></vxe-column>
					<vxe-column field="yearRate" title="超耗率" width="100"></vxe-column>
				</vxe-colgroup>
				<vxe-colgroup title="开累">
					<vxe-column field="allSj" title="设计量(含变更)" width="100"></vxe-column>
					<vxe-column field="allYh" title="应耗量" width="100"></vxe-column>
					<vxe-column field="allSh" title="实耗量" width="100"></vxe-column>
					<vxe-column field="allJc" title="节+超-" width="100"></vxe-column>
					<vxe-column field="allRate" title="超耗率" width="100"></vxe-column>
				</vxe-colgroup>
			</vxe-table>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowMonth2} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			allAlign: 'center',
			tableData: [],
			month: ''
		}
	},
	created() {
		this.month = setNowMonth2()
		this.getAllMessage()
	},
	methods: {
		getAllMessage(){
			this.axios.get('/materialWaterproofConsumeTest/getAllMessage', (response) => {
				this.tableData = response.obj
			},{
				month: this.month
			})
		}
	}
}
</script>

<style>

</style>